import { LoadState } from "../../constants/enums";
import {
  IDeliveryChallan,
  IDeliveryChallanItem,
  IDeliveryChallanState,
} from "./deliveryChallan.types";

export const defaultDeliveryChallantem: IDeliveryChallanItem = {
  product_uuid: "",
  product_name: "",
  category_uuid: "",
  category_name: "",
  product_type: null,
  customer_product_name: "",
  description: "",
  item_type: null,
  HSN_code: "",
  quantity: 0,
  unit: "",
  other_unit: "",
  unit_price: 0,
  discount: 0,
  discount_amount: 0,
  cess_rate: 0,
  cess_amount: 0,
  taxable_amount: 0,
  tax_rate: 0,
  tax_amount: 0,
  tax_type: "IGST",
  total: 0,
};

export const defaultDeliveryChallan: IDeliveryChallan = {
  invoice_items: [defaultDeliveryChallantem],
  delivery_challan_uuid: null,
  delivery_challan_no: "",
  delivery_challan_type: "Challan",
  date: null,
  lr_no: null,
  eway_no: null,
  reason_for_eway: null,
  delivery_mode: null,
  billing_company_name: null,
  billing_company_uuid: "",
  billing_company_branch_name: null,
  billing_company_branch_uuid: null,
  billing_company_branch_state: null,
  customer_uuid: "",
  customer_name: "",
  customer_registration_type: null,
  customer_type: "",
  customer_gstin: "",
  customer_branch_uuid: "",
  customer_branch_name: "",
  customer_address_line1: null,
  customer_address_line2: null,
  customer_address_city: null,
  customer_address_state: null,
  customer_address_pincode: null,
  customer_address_country: null,
  dispatch_address_line1: null,
  dispatch_address_line2: null,
  dispatch_address_city: null,
  dispatch_address_state: null,
  dispatch_address_pincode: null,
  dispatch_address_country: null,
  total_amount: 0,
  is_round_off: 0,
  total_amount_after_tax: 0,
  delivery_type: "",
  dispatch_through: null,
  transport_id: null,
  transport_doc_no: null,
  transport_doc_date: null,
  vehicle_no: null,
  distance_for_eway: null,
  delivery_address_line1: null,
  delivery_address_line2: null,
  delivery_address_city: null,
  delivery_address_state: null,
  delivery_address_pincode: null,
  delivery_address_country: null,
  tcs: 0,
  tcs_amount: 0,
  tcs_sign: null,
  discount: 0,
  discount_amount: 0,
  status: "ACTIVE",
};

export const defaultDeliveryChallanState: IDeliveryChallanState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  deliveryChallan: {
    data: defaultDeliveryChallan,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
