import { LoadState } from "../../constants/enums";
import { defaultInvoiceItem } from "../invoice";
import { IQuotation, IQuotationState } from "./quotation.types";

export const defaultQuotation: IQuotation = {
  quotation_uuid: null,
  quotation_no: "",
  quotation_date: null,
  challan_no: null,
  challan_date: null,
  quotation_type: null,
  lr_no: null,
  place_of_supply: null,
  billing_company_uuid: null,
  billing_company_name: null,
  billing_company_branch_uuid: null,
  billing_company_branch_name: null,
  billing_company_branch_state: null,
  customer_type: "N/A",
  customer_uuid: "",
  customer_name: "",
  customer_registration_type: null,
  customer_branch_uuid: "",
  customer_branch_name: "",
  customer_gstin: "",
  customer_address_line1: null,
  customer_address_line2: null,
  customer_address_city: null,
  customer_address_state: null,
  customer_address_pincode: null,
  customer_address_country: null,
  delivery_name: null,
  delivery_address_line1: null,
  delivery_address_line2: null,
  delivery_address_city: null,
  delivery_address_state: null,
  delivery_address_pincode: null,
  delivery_address_country: null,
  contact_uuid: null,
  contact_name: null,
  phone_number: null,
  delivery_type: "",
  dispatch_through: null,
  transport_id: null,
  transport_doc_no: null,
  transport_doc_date: null,
  vehicle_no: null,
  remark: null,
  advising_bank_uuid: null,
  advising_bank_name: null,
  invoice_items: [defaultInvoiceItem],
  total_amount: 0,
  is_round_off: 0,
  term_and_condition: null,
  total_amount_after_tax: 0,
  status: "ACTIVE",
  tcs: 0,
  tcs_amount: 0,
  tcs_sign: null,
  discount: 0,
  discount_amount: 0,
};

export const defaultQuotationState: IQuotationState = {
  quotation_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
  single_quotation: {
    data: defaultQuotation,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
